var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-card-light-thin  mb-3 px-0  v-card v-sheet theme--light elevation-0"},[_c('v-card-title',{staticClass:"pt-2 pb-0 mb-10 card-title-bloque"},[_c('p',{staticClass:"tit_card  "},[_vm._v(" "+_vm._s(_vm.$t('Mis datos complementarios para IA'))+" ")])]),_c('p',{staticClass:"px-8"},[_vm._v(" "+_vm._s(_vm.$t('Con estos datos de tu empresa ayudarás a nuestra IA a generar resultados mas precisos en tus sugerencias de contenido.'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Ninguno de los campos es obligatorio.'))+" ")]),_c('v-form',[_c('v-container',{staticClass:"px-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('h5',{staticClass:"pb-3"},[_vm._v(" "+_vm._s(_vm.$t('Información principal'))+" ")]),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-value":"id","item-text":"name","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Tipo de Empresa'),"items":_vm.listsData.AiUserProfileCompanyTypes},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.companyTypes),callback:function ($$v) {_vm.$set(_vm.aiProfile, "companyTypes", $$v)},expression:"aiProfile.companyTypes"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Tono de Comunicación'),"items":_vm.listsData.AiUserProfileCommunicationTones},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.communicationTones),callback:function ($$v) {_vm.$set(_vm.aiProfile, "communicationTones", $$v)},expression:"aiProfile.communicationTones"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Público Objetivo'),"items":_vm.listsData.AiUserProfileTargetAudiences},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.targetAudiences),callback:function ($$v) {_vm.$set(_vm.aiProfile, "targetAudiences", $$v)},expression:"aiProfile.targetAudiences"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Objetivo de la Comunicación'),"items":_vm.listsData.AiUserProfileCommunicationObjectives},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.communicationObjectives),callback:function ($$v) {_vm.$set(_vm.aiProfile, "communicationObjectives", $$v)},expression:"aiProfile.communicationObjectives"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Sectores'),"items":_vm.listsData.AiUserProfileSectorss},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.sectors),callback:function ($$v) {_vm.$set(_vm.aiProfile, "sectors", $$v)},expression:"aiProfile.sectors"}})],1),_c('v-col',{staticClass:"px-10 pt-10 ",attrs:{"cols":"12","sm":"4"}},[_c('img',{attrs:{"src":require("@/assets/img/ia/datos-ia.png")}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('Información secundaria')))])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Estilo de Escritura Preferido'),"items":_vm.listsData.AiUserProfileWritingStyles},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.writingStyles),callback:function ($$v) {_vm.$set(_vm.aiProfile, "writingStyles", $$v)},expression:"aiProfile.writingStyles"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Modelo de negocio'),"items":_vm.listsData.AiUserProfileBusinessModels},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.businessModels),callback:function ($$v) {_vm.$set(_vm.aiProfile, "businessModels", $$v)},expression:"aiProfile.businessModels"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Valores de la Empresa'),"items":_vm.listsData.AiUserProfileCompanyValues},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.companyValues),callback:function ($$v) {_vm.$set(_vm.aiProfile, "companyValues", $$v)},expression:"aiProfile.companyValues"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Frecuencia de Comunicación'),"items":_vm.listsData.AiUserProfileCommunicationFrequencys},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.communicationFrequencies),callback:function ($$v) {_vm.$set(_vm.aiProfile, "communicationFrequencies", $$v)},expression:"aiProfile.communicationFrequencies"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Objetivos de la Empresa'),"items":_vm.listsData.AiUserProfileCompanyObjectivess},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.companyObjectives),callback:function ($$v) {_vm.$set(_vm.aiProfile, "companyObjectives", $$v)},expression:"aiProfile.companyObjectives"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Comportamiento del Cliente'),"items":_vm.listsData.AiUserProfileCustomerBehaviors},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.customerBehaviors),callback:function ($$v) {_vm.$set(_vm.aiProfile, "customerBehaviors", $$v)},expression:"aiProfile.customerBehaviors"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Canal de comunicación preferido'),"items":_vm.listsData.AiUserProfileCommunicationChannels},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.communicationChannels),callback:function ($$v) {_vm.$set(_vm.aiProfile, "communicationChannels", $$v)},expression:"aiProfile.communicationChannels"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Imagen de la Marca'),"items":_vm.listsData.AiUserProfileBrandImages},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.brandImages),callback:function ($$v) {_vm.$set(_vm.aiProfile, "brandImages", $$v)},expression:"aiProfile.brandImages"}}),_c('v-select',{attrs:{"multiple":"","small-chips":"","item-text":"name","item-value":"id","filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Misiones y Visiones'),"items":_vm.listsData.AiUserProfileMissionsVisions},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(item.name))}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])]}}]),model:{value:(_vm.aiProfile.missionsVisions),callback:function ($$v) {_vm.$set(_vm.aiProfile, "missionsVisions", $$v)},expression:"aiProfile.missionsVisions"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('Otra información de interés sobre mi negocio')))])]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{staticClass:"pb-0 mb-0 textarea-sms ",attrs:{"filled":"","background-color":"blue-grey lighten-5","label":_vm.$t('Información de interés')},model:{value:(_vm.aiProfile.interestInfo),callback:function ($$v) {_vm.$set(_vm.aiProfile, "interestInfo", $$v)},expression:"aiProfile.interestInfo"}})],1)],1),_c('v-row',{staticClass:"px-10 pb-8",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mt-3 mb-3 float-right",attrs:{"disabled":!_vm.$permissions.hasPermission('edit-nia-profile'),"elevation":"0","rounded":"","right":"","block":"","color":"light-blue darken-1 white--text"},on:{"click":_vm.saveProfileData}},[_vm._v(" "+_vm._s(_vm.$t('Guardar los datos para IA'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"white"}},[_vm._v(" far fa-save ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }